<template>
  <b-row>

    <!-- Description -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Description"
        rules="required"
      >
        <b-form-group
          label="Description"
          label-for="description"
        >
          <b-form-input
            id="description"
            v-model="menuData.description"
            :state="getValidationState(validationContext)"
            placeholder="Description"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Description AR-->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Description AR"
        rules="required"
      >
        <b-form-group
          label="Description AR"
          label-for="description-ar"
        >
          <b-form-input
            id="description-ar"
            v-model="menuData.descriptionAr"
            :state="getValidationState(validationContext)"
            placeholder="Description AR"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Resource -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Resource"
        rules="required"
      >
        <b-form-group
          label="Resource"
          label-for="resource"
        >
          <b-form-input
            id="resource"
            v-model="menuData.resource"
            :state="getValidationState(validationContext)"
            placeholder="Resource"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Route -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Route"
        rules="required"
      >
        <b-form-group
          label="Route"
          label-for="route"
        >
          <b-form-input
            id="route"
            v-model="menuData.route"
            :state="getValidationState(validationContext)"
            placeholder="Route"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Operation -->
    <b-col sm="6">
      <b-form-group
        class="mb-1"
        label="Operation"
        label-for="operation"
      >
        <v-select
          v-model="menuData.defaultAction"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="operationOptions"
          class="w-100"
          :reduce="val => val.value"
          input-id="operation"
        />
      </b-form-group>
    </b-col>

    <!-- Module -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Module"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Module"
          label-for="module"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="menuData.moduleId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="moduleOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="module"
            :clearable="false"
            @input="fetchMenus(menuData.moduleId)"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Is App? -->
    <!-- <b-col sm="6">
      <b-form-group
        label="Is App?"
        label-for="is-app"
      >
        <b-form-checkbox
          id="is-app"
          v-model="menuData.is_app"
          value="1"
          unchecked-value="0"
          name="is-app"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-col> -->

    <!-- Is Sub Menu? -->
    <b-col sm="6">
      <b-form-group
        label="Is Sub Menu?"
        label-for="is-sub-menu"
      >
        <b-form-checkbox
          id="is-sub-menu"
          v-model="menuData.isSubmenu"
          :value="true"
          :unchecked-value="false"
          name="is-sub-menu"
          switch
          @change="menuData.parentMenuId = null"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Menu -->
    <b-col
      v-if="menuData.moduleId && menuData.isSubmenu"
      sm="6"
    >
      <validation-provider
        #default="validationContext"
        name="Menu"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Menu"
          label-for="menu"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="menuData.parentMenuId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="parentMenuOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="menu"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Show in Sidebar? -->
    <b-col sm="6">
      <b-form-group
        label="Show in Sidebar?"
        label-for="show-in-sidebar"
      >
        <b-form-checkbox
          id="show-in-sidebar"
          v-model="menuData.showInSidebar"
          :value="1"
          :unchecked-value="0"
          name="show-in-sidebar"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import useModules from '../../modules/useModules'
import useMenus from '../useMenus'
import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'
import { operationOptions } from '@/utils/computed-properties/system-codes/systemCodesComputedProperties'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,

    vSelect,

    //  Form Validation
    ValidationProvider,
  },

  directives: {
    Ripple,
  },
  props: {
    menuData: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const { moduleOptions } = useModules()

    const { parentMenuOptions, fetchMenus } = useMenus()

    const { callOperations } = useSystemCodes()
    callOperations()

    const {
      getValidationState,
    } = formValidation()

    return {
      // Actions
      fetchMenus,

      // Options
      moduleOptions,
      parentMenuOptions,
      operationOptions,

      //  Form
      getValidationState,
    }
  },
}
</script>
